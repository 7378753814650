import React from 'react'
import { graphql } from 'gatsby'
import LocalizedLink from '../components/LocalizedLink';
import SEO from '../components/seo'
import * as L from '../components/Solution/styled'
import * as S from '../components/Content/styled';
import '../components/CSS/campaign.css'
import useTranslations from '../components/useTranslations'
import DocumentDownload from "../components/LandingPage/documentDownload"

const Campaign = props => {
  const post = props.data.markdownRemark
  const { retmonTitle, navbar } = useTranslations()

  return (
    <>

      <SEO
        title={`${post.frontmatter.title} | ${retmonTitle}`}
        description={post.frontmatter.description}
        image={post.frontmatter.imageURL}
      />
      { post.frontmatter.isImage ? <img className='campaign-image' src={post.frontmatter.bannerURL} alt={post.frontmatter.title} /> : null}
      <L.Title
        style={{
          fontWeight: '900'
        }}>{post.frontmatter.title}</L.Title>
      <L.SubTitle>{post.frontmatter.code}</L.SubTitle>

      <S.Content>
        <div dangerouslySetInnerHTML={{ __html: post.html }}></div>
      </S.Content>
      <L.Row justify='left'>
        <L.RetmonCTAButton style={{ padding: '1.3rem' }} >
          <LocalizedLink to={`/${navbar.contactURL}/`}>{post.frontmatter.ctaTitle}</LocalizedLink>
        </L.RetmonCTAButton>
      </L.Row>
    </>
  )
}

export const query = graphql`
  query Campaign($locale: String!, $title: String!) {
        markdownRemark(
      frontmatter: {title: {eq: $title } }
      fields: {locale: {eq: $locale } }
    ) {
        frontmatter {
        title
        code
        ctaTitle
        imageURL
        description
        bannerURL
        isImage
        subTitle
    }
    html
  }
}
`

export default Campaign
